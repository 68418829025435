import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFacebook, faInstagram } from "@fortawesome/free-brands-svg-icons";
import { Modal, Box, Typography } from "@mui/material";
import { useTranslation } from 'react-i18next';
import { SaveEmail } from '../api/emailService';
import PhoneNumberLink from "./Utils/PhoneNumberLink";

const style = {
	position: "absolute",
	top: "50%",
	left: "50%",
	transform: "translate(-50%, -50%)",
	width: 400,
	bgcolor: "background.paper",
	border: "2px solid #000",
	boxShadow: 24,
	p: 4,
};

const Footer = () => {
	const [open, setOpen] = useState(false);
	const [email, setEmail] = useState("");
	const [message, setMessage] = useState("");
	const [title, setTitle] = useState("");
	const [isLoading, setIsLoading] = useState(false); 
	const handleClose = () => setOpen(false);
	const [t, i18n] = useTranslation("global");

	const handleSubmit = async (e) => {
		e.preventDefault();
		setIsLoading(true);

		try {
			const language = i18n.language.toUpperCase();
			const response = await SaveEmail(email, language, t);
			
			console.log(response)

			if (response.status === 409) {
				setTitle(t("footer.modalErrorTitle"));
				setMessage(t("footer.modalDuplicationError"));
			} else if (response.status === 200) {
				setTitle(t("footer.modalTitle"));
				setMessage(t("footer.modalContent"));
			} else {
				setTitle(t("footer.modalErrorTitle"));
				setMessage(t("footer.modalContentError"));
			}
		} catch (error) {
			setMessage(t("footer.modalContentError")); 
			setTitle(t("footer.modalErrorTitle"));
		} finally {
			setIsLoading(false);
			setOpen(true);
		}
	};

	return (
		<div id="footer" className="bg-gray-900">
			<div className="container py-8 mx-auto text-center">
				<h2 className="text-3xl font-bold text-white">
					<a
						href={t("footer.instagramUrl")}
						target="_blank"
						className="text-white hover:no-underline hover:text-gray-400"
					>
						{t("footer.followText")}
					</a>
				</h2>
			</div>

			<div className="flex flex-col lg:flex-row">
				<div className="flex flex-col place-content-around w-full lg:w-1/2 bg-black p-6 lg:p-10">
					<div className="mb-5">
						<h3 className="text-white text-lg font-semibold mb-5">
							<span className='text-lg font-semibold text-primary-200'>2</span>
							<span className="text-lg font-semibold">
								Fit
							</span>
							<span className='text-lg font-semibold text-primary-200'> AI</span>
						</h3>
						<p className="text-gray-400 mb-2 ">
							{t("footer.developedByText")} {t("footer.developedBy")}
							<br />
						</p>
						<p className="text-xs">{t("footer.direction")}</p>
					</div>

					<div className="flex flex-col place-content-around bg-gray-800 py-2 px-3 w-full lg:w-3/4">
						<p className="text-primary-200 pl-2 pt-4">
							{t("footer.callText")}<br></br>
						</p>
						<p className="text-gray-400 pl-2">
							<PhoneNumberLink phoneNumber={t("footer.phone1")}></PhoneNumberLink>
							<br></br>
							<PhoneNumberLink phoneNumber={t("footer.phone2")}></PhoneNumberLink>
						</p>
						<p className="text-primary-200 pl-2 pt-4">
							{t("footer.mailText")}<br></br>
						</p>
						<a className="text-blue-500 mb-4 pl-2" href={t("footer.mailTo")}>
							{t("footer.mail")}
							<br></br>
						</a>
					</div>
				</div>

				<div className="flex flex-col place-content-between w-full lg:w-1/2 bg-black p-6 lg:p-10">
					<div>
						<h3 className="text-white text-lg font-semibold mb-6">INFO</h3>
						<p className="text-gray-400 mb-6 ">
							{t("footer.infoDescription")}
							<br></br>
							{t("footer.joinDescription")}
						</p>
					</div>
					<div className="py-0.25">
						<form method="post" onSubmit={handleSubmit}>
							<input
								type="email"
								id="email"
								name="email"
								value={email}
								onChange={(e) => setEmail(e.target.value)}
								className="w-full lg:w-1/2 bg-gray-800 text-white py-2 px-3 rounded-sm mb-2"
								placeholder={t("footer.emailPlaceHolder")}
								required
							/>
							<br></br>
							<button
								type="submit"
								className="w-full lg:w-1/2 bg-green-500 text-white py-2 px-4 shadow-lg hover:bg-green-600 transition-colors duration-300 mb-6"
								disabled={isLoading}
							>
								{isLoading ? (
									<div className="loader"></div>
								) : (
									t("footer.textBtn")
								)}
							</button>
						</form>
						<Modal
							open={open}
							onClose={handleClose}
							aria-labelledby="modal-modal-title"
							aria-describedby="modal-modal-description"
						>
							<Box sx={style}>
								<Typography id="modal-modal-title" variant="h6" component="h2">
									<p className="font-bold">{title}</p>
								</Typography>
								<Typography id="modal-modal-description" sx={{ mt: 2 }}>
									{message}  
								</Typography>
							</Box>
						</Modal>
					</div>
					<div className="mt-8 lg:mt-0">
						<p className="text-center lg:text-left text-gray-400">
							{t("footer.writeUsText")}
							<a className="text-blue-500 mb-4 pl-2" href={t("footer.mailTo")}>
								{t("footer.mail")}
							</a>
							{t("footer.or")}
						</p>
						<p className="text-center lg:text-left text-gray-400">
							{t("footer.followUsText2")}
						</p>
						<p className="text-center mt-2 lg:text-left">
							<a href="https://facebook.com" className="text-blue-600 hover:text-blue-700 pl-2">
								<FontAwesomeIcon icon={faFacebook} size="2x" />
							</a>
							<a href={t("footer.instagramUrl")} className="text-pink-500 hover:text-pink-600 pl-2">
								<FontAwesomeIcon icon={faInstagram} size="2x" />
							</a>
						</p>
					</div>
				</div>
			</div>
		</div>
	);
};

export default Footer;
