import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import global_en from './translations/en/global.json';
import global_es from './translations/es/global.json';
import i18next from 'i18next';
import { I18nextProvider, initReactI18next } from 'react-i18next';
import i18nextBrowserLanguagedetector from 'i18next-browser-languagedetector';

i18next
.use(i18nextBrowserLanguagedetector)
.use(initReactI18next)
.init({
  resources: {
    en: {
      global: global_en
    },
    es: {
      global: global_es
    } 
  },
  fallbackLng: "es",
  detection: {
    order: ['querystring', 'cookie', 'localStorage', 'navigator'],
    caches: ['localStorage', 'cookie']
  },
  interpolation: {escapeValue: false},

})

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <I18nextProvider i18n={i18next}>
      <App />
    </I18nextProvider>
  </React.StrictMode>
);
