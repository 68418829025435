import esFlag from '../assets/img/flags/es.png';
import enFlag from '../assets/img/flags/en.png';
import i18n from 'i18next';

const languagesList = [
    {
        img: esFlag,
        lng: 'ES',
        label: "Spanish"
    },
    {
        img: enFlag,
        lng: 'EN',
        label: "English"
    }
]

export const changeLanguage = (language) => {
    i18n.changeLanguage(language.toLowerCase());
};

export default languagesList;