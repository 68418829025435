import React, { useState } from 'react';
import languagesList, { changeLanguage } from '../../translations/Languages';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';

const LanguajeDropdown = () => {
    const { i18n } = useTranslation();
    const currentLanguage = i18n.language.toUpperCase();
	const [dropdownOpen, setDropdownOpen] = useState(false);

    const handleLanguageChange = (language) => {
		setDropdownOpen(false);
        changeLanguage(language.toLowerCase());

	};
    const currentLanguageObj = languagesList.find(lang => lang.lng === currentLanguage);

    return (<div className="hidden md:block">
        <div className="relative">
            <button
                className="flex items-center space-x-2"
                onClick={() => setDropdownOpen(!dropdownOpen)}
            >
                {currentLanguageObj && (
                    <img src={currentLanguageObj.img} alt={currentLanguage.label} className="w-5 h-5" />
                )}
                <span className='text-white'>{currentLanguage}</span>
            </button>
            {dropdownOpen && (
                <div className="absolute right-0 mt-2 w-32 bg-white shadow-lg z-20">
                {_.map(languagesList, (item, index) => (
                    <button 
                        key={index}
                        className="flex items-center space-x-2 w-full px-4 py-2 hover:bg-gray-400"
                        onClick={() => handleLanguageChange(item.lng)}
                    >
                        <img src={item.img} alt={item.label} className="w-5 h-5" />
                        <span>{item.label}</span>
                    </button>
                ))}
            </div>
            )}
        </div>
    </div>
    );
};

export default LanguajeDropdown;