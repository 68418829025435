import React from "react";
import info_img from "../assets/img/community/img1.png";
import motivation_img from "../assets/img/workouts/battle-rope.png";
import inspiration_img from "../assets/img/workouts/resistance.png";
import ItemWithNumber from "./Utils/ItemWNumber";
import { useTranslation } from 'react-i18next';
import { WAContact } from '../data';

const Information = () => {
    const [t] = useTranslation("global");
    const {Number,Message} = WAContact;

    const handleWhatsAppClick = () => {
        window.open(`https://wa.me/${Number}?text=${Message}`, '_blank');
    };

    return (
        <div className="mb-20">
            <div className="about-section p-20 flex place-content-center">
                <div className="flex flex-col md: flex-1 w-50">
                    <h2 className="text-purple-500 text-lg tracking-wide">{t("information.title")}</h2>
                    <h1 className="text-4xl mt-4 mb-6 font-bold">{t("information.sectionHeader")}</h1>
                    <p className="text-gray-600 mb-10">{t("information.sectionText")}</p>

                    <div className="boxes flex flex-col place-content-center">

                        <div className="mb-5 p-5 text-center">
                            <div className="hidden md:flex flex-row bg-gray-100">
                                <div className="basis-3/4 p-10">
                                    <h3 className="text-2xl mb-2 text-left font-bold">{t("information.boxOneTitle")}</h3>
                                    <p className="text-gray-600 text-left">{t("information.boxOneText")}</p>
                                </div>
                                <div className="basis-1/4 place-content-center">
                                    <img
                                        src={motivation_img}
                                        alt="Motivation"
                                        className="rounded-lg"
                                    />
                                </div>
                            </div>
                            <div className="block md:hidden relative bg-cover bg-center p-10"
                                style={{ backgroundImage: `url(${motivation_img})` }}>
                                <div className="absolute inset-0 bg-gray-700 opacity-75"></div>
                                <div className="relative">
                                    <h3 className="text-2xl mb-2 text-left font-bold text-white">{t("information.boxOneTitle")}</h3>
                                    <p className="text-white text-left">{t("information.boxOneText")}</p>
                                </div>
                            </div>
                        </div>

                        {/* Box 2 Inspiration */}
                        <div className="mb-5 p-5 text-center">
                            <div className="hidden md:flex flex-row bg-gray-100">
                                <div className="basis-3/4 p-10">
                                    <h3 className="text-2xl mb-2 text-left font-bold">{t("information.boxTwoTitle")}</h3>
                                    <p className="text-gray-600 text-left">{t("information.boxTwoText")}</p>
                                </div>
                                <div className="basis-1/4 place-content-center">
                                    <img
                                        src={inspiration_img}
                                        alt="Motivation"
                                        className="rounded-lg"
                                    />
                                </div>
                            </div>
                            <div className="block md:hidden relative bg-cover bg-center p-10"
                                style={{ backgroundImage: `url(${inspiration_img})` }}>
                                <div className="absolute inset-0 bg-gray-700 opacity-75"></div>
                                <div className="relative">
                                    <h3 className="text-2xl mb-2 text-left font-bold text-white">{t("information.boxTwoTitle")}</h3>
                                    <p className="text-white text-left">{t("information.boxTwoText")}</p>
                                </div>
                            </div>
                        </div>

                    </div>

                    <div className="md:hidden mt-10 flex place-content-center">
                        <button 
                            className="bg-blue-500 text-white py-2 px-4"
                            onClick={handleWhatsAppClick}
                        >
                            {t("information.getStartedButtonText")}
                        </button>
                    </div>
                </div>

                <div className="flex-1 w-50 place-content-center hidden md:block">
                    <div className="image-section relative text-center m-10">
                        <img src={info_img} alt="Workout" className="rounded-lg mx-auto" />
                        <button 
                            className="bg-blue-500 text-white py-2 px-4 absolute bottom-4 left-1/2 transform -translate-x-1/2"
                            onClick={handleWhatsAppClick}
                        >
                            {t("information.getStartedButtonText")}
                        </button>
                    </div>
                </div>
            </div>
            <div className="">
                
            </div>
            <div className="flex flex-col md:flex-row justify-between mx-5 md:mx-20 py-5 border-t border-b border-gray-300 place-content-between">
                <ItemWithNumber number={1} text={t("information.itemsText1")} className="mb-4 md:mb-0" />
                <ItemWithNumber number={2} text={t("information.itemsText2")} className="mb-4 md:mb-0" />
                <ItemWithNumber number={3} text={t("information.itemsText3")} className="mb-4 md:mb-0" />
                {/* <div className="footer-item text-gray-600 place-content-center">Review on Google ★★★★★</div> */}
            </div>
        </div>
    );
};

export default Information;
