import React from 'react';
import _ from 'lodash';
import languagesList, { changeLanguage } from '../translations/Languages';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-scroll';

const NavMobile = ({ navMobile, setNavMobile }) => {
	const [t] = useTranslation("global");
	return (
		<div className={`${navMobile ? 'min-h-screen' : 'min-h-0'} md:hidden w-full bg-neutral-500 fixed top-0 left-0 right-0 -bottom-12 -z-10 overflow-hidden transition-all h-0`}>
			<div id="button" className="absolute inset-0 flex justify-center items-center" style={{ marginTop: '-50px' }}>
				<ul className='w-full h-full flex flex-col justify-center items-center gap-y-8'>
					<Link 
                        to="footer" 
                        smooth={true} 
                        onClick={() => setNavMobile(false)}
                    >
                        <button className='btn btn-lg btn-primary'>{t("footer.textBtn")}</button>
                    </Link>
				</ul>
			</div>
			<div id="languages" className={`${navMobile ? 'fixed' : 'hidden'} bottom-8 w-full flex justify-center`}>
				{_.map(languagesList, (item, index) => (
					<button
						key={index}
						className="flex items-center space-x-2 mx-3"
						onClick={() => changeLanguage(item.lng.toLowerCase())}
					>
						<img src={item.img} alt="flag" className="w-5 h-5" />
						<span className='text-white'>{item.label}</span>
					</button>
				))}
			</div>
		</div>
	);
};

export default NavMobile;
