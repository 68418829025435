// App.jsx
import React from 'react';
import Aos from 'aos';
import 'aos/dist/aos.css';
import { useInView } from 'react-intersection-observer';
import Banner from './components/Banner';
import Header from './components/Header';
import About from './components/About';
import Footer from './components/Footer';
import Information from './components/Information';
import Benefits from './components/Benefits';
import WaButton from './components/WaButton';
const App = () => {
  // Verificación del entorno
  if (process.env.NODE_ENV === 'development') {
    console.log('Running in development mode');
  } else if (process.env.NODE_ENV === 'production') {
    console.log('Running in production mode');
  }
  
  Aos.init({
    duration: 2500,
    delay: 400,
  });

  const [section1Ref, section1InView] = useInView({ threshold: 0.01 });
  const [section2Ref, section2InView] = useInView({ threshold: 0.01 });
  const [section3Ref, section3InView] = useInView({ threshold: 0.01 });
  const [footerRef, footerInView] = useInView({ threshold: 0.01 });

  return (
    <div>
      <Header 
        section1InView={section1InView} 
        section2InView={section2InView} 
        section3InView={section3InView} 
        footerInView={footerInView}
      />
      <WaButton/>
      <div className="Container">
        <section className="Section" ref={section1Ref}>
          <Banner />
        </section>
        <section className="Section" ref={section1Ref}>
          <About />
        </section>
        <section className="Section" ref={section2Ref}>
          <Information />
        </section>
        <section className="Section" ref={section3Ref}>
          <Benefits />
        </section>
      </div>
      <div ref={footerRef}>
        <Footer />
      </div>
    </div>
  );
  
};

export default App;
