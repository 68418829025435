import React from 'react';
import { Link } from 'react-scroll';
import { IoIosArrowDropdownCircle } from 'react-icons/io'
import { useTranslation } from 'react-i18next';

const About = () => {
	const [t] = useTranslation("global");
	//destructure about data
	const title = t("about.title");
	const titleParts = title.split('2');

	return (
		<section className=' bg-neutral-500 py-[80px] md:py-[110px] lg:pt-[140px] lg:pb-[180px]'>
			<div id="About" className='container mx-auto px-[20px] lg:px-[135px]'>
				<div className='section-title-group justify-center md:justify-start'
					data-aos='fade-up' data-aos-delay='100'>
					<h2 className='text-2xl md:text-4xl text-white font-bold whitespace-nowrap'>
						{titleParts[0]}<span className='text-primary-200'>2</span>Fit <span className='text-primary-200'>AI</span>?
					</h2>
				</div>
				<p className='md:text-body-md mb-12 text-white px-2 md:px-0'
					data-aos='fade-up' data-aos-delay='200'>{t("about.subtitle1")}</p>
				{/*Subtitle2*/}
				<p className='md:text-body-md mb-12 text-white px-2 md:px-0' data-aos='fade-up' data-aos-delay='300'>{t("about.subtitle2")}</p>
				{/*link*/}
				<div data-aos='fade-up' data-aos-delay='400'>
					<Link to="footer" smooth={true}>
						<a className='link flex justify-center md:justify-start gap-x-4 hover:gap-x-6 transition-all text-white' href='#'>
							{t("about.link")}
							<IoIosArrowDropdownCircle className='text-2x1 text-white' />
						</a>
					</Link>
				</div>
			</div>
		</section>
	);
};

export default About;
