// Header.jsx
import React, { useState, useEffect } from 'react';
import { Link } from 'react-scroll';
import NavMobile from '../components/NavMobile';
import { useTranslation } from 'react-i18next';
import LanguajeDropdown from './Utils/LanguageDropdown';
import { RiMenu4Fill, RiCloseFill } from 'react-icons/ri';
import './Styles.css';

const Header = ({ section1InView, section2InView, section3InView, footerInView }) => {
  const [t] = useTranslation("global");
  const [navMobile, setNavMobile] = useState(false);
  const [backgroundColor, setBackgroundColor] = useState('');

  useEffect(() => {
    const updateBackgroundColor = () => {
      if (section1InView) {
        setBackgroundColor('');
      } else if (section2InView) {
        setBackgroundColor('black');
      } else if (section3InView) {
        setBackgroundColor('');
      }
    };

    updateBackgroundColor();
  }, [section1InView, section2InView, section3InView]);

  return (
    <div id="Header" className="w-full fixed z-30">
      <header
        className="py-4 w-full max-w-full flex justify-between items-center px-4 md:px-10 lg:px-20"
        style={{ backgroundColor, transition: 'background-color 0.6s ease' }}
      >
        {/* Logo */}
        <div className="flex-1">
          <a href="#">
            <span className='text-lg font-semibold text-primary-200'>2</span>
            <span className={`h-[30px] text-lg font-semibold mb md:mb-4 ${backgroundColor === 'white' ? 'text-black' : 'text-white'}`}>
              Fit
            </span>
            <span className='text-lg font-semibold text-primary-200'> AI</span>
          </a>
        </div>

        {/* Nav - show on desktop */}
        <div className={`hidden lg:flex space-x-4 mr-4 ${footerInView ? 'fade-out' : 'fade-in'}`}>
          <Link to="footer" smooth={true}>
            <button className="btn btn-lg btn-primary">{t("header.btnJoinUsText")}</button>
          </Link>
        </div>

        <LanguajeDropdown />

        {/* Nav menu btn - hide on desktop */}
        <div
          onClick={() => setNavMobile(!navMobile)}
          className="lg:hidden absolute right-4"
        >
          {navMobile ? (
            <RiCloseFill className="text-primary-200 text-3xl cursor-pointer" />
          ) : (
            <RiMenu4Fill className="text-primary-200 text-3xl cursor-pointer" />
          )}
        </div>

                {/* Nav mobile - hide on desktop */}
                <NavMobile navMobile={navMobile} setNavMobile={setNavMobile}/>
            </header>
        </div>
    );
};

export default Header;
