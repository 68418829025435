import React from "react";
import _ from "lodash";
import guy_img from "../assets/img/benefits/img1.png";
import icon from "../assets/img/benefits/icon1.png";
import { useTranslation } from 'react-i18next';

const Benefits = () => {
  const { t } = useTranslation("global");
  const benefitsList = t("benefits.benefitsList", { returnObjects: true});

  return (
    <div className="bg-[#524FF5] text-white flex flex-col lg:flex-row justify-between p-[2rem] lg:p-[7rem] ">
      <div className="flex-1 place-content-center mb-8 lg:mb-0">
        <h2 className="uppercase text-white text-[15px] tracking-[4px]">
          {t("benefits.sectionSubtitle")}
        </h2>
        <h1 className="text-white mb-3 font-bold text-2xl lg:text-3xl">
          {t("benefits.title")}
        </h1>
        <p className="capitalize mb-10 text-white text-sm">
          {t("benefits.sectionText")}
        </p>
        <img
          height={535}
          width={470}
          src={guy_img}
          alt="Guy Flexing"
          className="w-full max-w-[470px] h-auto"
        />
      </div>

      <div className="flex-1 text-sm capitalize">
        {_.map(benefitsList, (item, index) => (
          <div key={index}>
            <hr className="my-8" />
            <span className="bg-black py-1.5 px-4 flex items-center">
              <img src={icon} className="inline-block mr-2" alt="" />
              {item.title}
            </span>
            <p className="mt-5 w-full lg:w-[27rem] [word-spacing:2px]">
              {item.description}
            </p>
          </div>
        ))}
        <hr className="my-6" />
      </div>
    </div>
  );
};

export default Benefits;
