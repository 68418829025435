import React, { useState, useEffect } from 'react';
import { FaWhatsapp } from 'react-icons/fa';
import './Styles.css'; 
import { useTranslation } from 'react-i18next';
//import data
import {WAContact} from '../data'

const WaButton = () => {
  const [t] = useTranslation("global");
  const {Number, Message} = WAContact;
  const [showMessage, setShowMessage] = useState(true);

  useEffect(() => {
    // Hide message after 6 seconds
    const timer = setTimeout(() => {
      setShowMessage(false);
    }, 6000);

    return () => clearTimeout(timer);
  }, []);

  const handleClick = () => {
    // WA API with prefilled message
    
    window.open(`https://wa.me/${Number}?text=${Message}`, '_blank');
    // window.open(`https://api.whatsapp.com/send/?phone=${Number}&text=${encodeURIComponent(Message)}`, '_blank');
  };

  return (
    <div className="wa-container">
      <div className="wa-content" onClick={handleClick}>
        {showMessage && <div className="wa-message">{t("WAContact.Message")}</div>}
        <div className="wa-button">
          <FaWhatsapp className="wa-icon" />
        </div>
      </div>
    </div>
  );
};

export default WaButton;
