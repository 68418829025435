import React from 'react';

const ItemWithNumber = ({ number, text }) => {
    return (
        <div className="flex items-center bg-gray-100 p-2">
            <div className="bg-gray-900 text-white px-2 py-1 mr-2">
                {number}
            </div>
            <div className="text-gray-800">
                {text}
            </div>
        </div>
    );
};

export default ItemWithNumber;