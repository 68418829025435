import React from 'react';

const PhoneNumberLink = ({ phoneNumber }) => {
    const isMobile = typeof window !== 'undefined' && /Mobi|Android|iPhone|iPad|iPod|Opera Mini|IEMobile|WPDesktop|Silk/.test(navigator.userAgent);

    return (
        <>
            {isMobile ? (
                <a href={`tel:${phoneNumber}`} className="text-blue-500">
                    {phoneNumber}
                </a>
            ) : (
                <a>{phoneNumber}</a>
            )}
        </>
    );
};

export default PhoneNumberLink;